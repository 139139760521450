import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from '@/router'
import '@/assets/styles/base.css'
import '@/assets/styles/main.css'
import '@/assets/styles/index.css'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ElMessage } from 'element-plus'
import {getToken,getRole} from '@/utils/auth'

// import './assets/font/font.css'

library.add(fas);



const app = createApp(App)

app.use(ElementPlus)

app.use(router)

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app')


// 导航守卫，判断是否登录

router.beforeEach((to, from, next) => {
    if('/adminIndex'==to.path || '/adminClass'==to.path || '/adminProject'==to.path || '/adminReview'==to.path){
        if('管理员'!=getRole() && '超级管理员'!=getRole()){
            next(
                {
                    path: '/home'
                },
                ElMessage.error('没有管理员权限')
            )
        }
    }
    if (to.meta.requireAuth) {
        if (getToken() !== null) {
            next()
        } else {
            next(
                {
                    path: '/login',
                    query: { redirect: to.fullPath }
                },
                ElMessage.error('未登录或登录已过期，请登录！')
            )
        }
    } else {
        next()
    }
});


