<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App'
}
</script>
<style>
#app {
    width: 100%;
    height: 100%;
  background-color: #fff;
    font-family: "PHT";
    font-size: 18px;
    color: #333333;
}
body,html{
    font-family: "PHT";
    height: 100%;

}
</style>
