
const TokenKey = 'Authorization'

const nickNameKey = 'nickName'

const uidKey = 'uid'

const headImgUrlKey = 'headImgUrl'

const IntegralKey = 'integral'

const roleKey = 'roleKey'


export const getToken = () => {
    return localStorage.getItem(TokenKey);
}

export const setToken = (token) => {
  return localStorage.setItem(TokenKey, token)
}

export const removeToken = () =>  {
  return localStorage.removeItem(TokenKey)
}

export const removeNickName = () =>  {
  return localStorage.removeItem(nickNameKey)
}

export const setNickName = (nickName) => {
  return localStorage.setItem(nickNameKey, nickName)
}

export const getNickName = () => {
  return localStorage.getItem(nickNameKey);
}

export const setUid = (uid) => {
  return localStorage.setItem(uidKey, uid)
}

export const getUid = () => {
  return localStorage.getItem(uidKey);
}

export const removeUid = () =>  {
  return localStorage.removeItem(uidKey)
}

export const getHeadImgUrl = () => {
  return localStorage.getItem(headImgUrlKey);
}

export const setHeadImgUrl = (headImgUrl) => {
return localStorage.setItem(headImgUrlKey, headImgUrl)
}

export const removeHeadImgUrl = () =>  {
  return localStorage.removeItem(headImgUrlKey)
}

export const getIntegral = () => {
  return localStorage.getItem(IntegralKey);
}

export const setIntegral = (integral) => {
return localStorage.setItem(IntegralKey, integral)
}

export const removeIntegral = () =>  {
  return localStorage.removeItem(IntegralKey)
}

export const setRole = (role) => {
  return localStorage.setItem(roleKey, role)
}

export const getRole = () => {
  return localStorage.getItem(roleKey)
}

export const removeRole = () => {
  return localStorage.removeItem(removeRole)
}

